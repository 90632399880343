import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"position":"relative","top":"0","left":"0"}},[_c('vue-pdf',_vm._b({ref:"pdfContainer",on:{"loaded":_vm.onPdfLoad}},'vue-pdf',_vm.pdfProps,false)),(_vm.useStampAndRecipientZone)?_c('div',{staticClass:"stamp-and-recipient-zone",style:({
        top: ((_vm.stampAndRecipientZoneInPX.posY) + "px"),
        left: ((_vm.stampAndRecipientZoneInPX.posX) + "px"),
        width: ((_vm.stampAndRecipientZoneInPX.width) + "px"),
        height: ((_vm.stampAndRecipientZoneInPX.height) + "px"),
      })}):_vm._e(),(_vm.localExampleRecipient.text != '')?_c('div',{staticClass:"exampleRecipient",style:({
        top: ((_vm.localExampleRecipient.posYInPX) + "px"),
        left: ((_vm.localExampleRecipient.posXInPX) + "px"),
        width: ((_vm.localExampleRecipient.widthInPX) + "px"),
        fontSize: ((_vm.localExampleRecipient.fontSizeInPX) + "px"),
        lineHeight: ((_vm.localExampleRecipient.lineHeightInPX) + "px"),
      }),domProps:{"innerHTML":_vm._s(_vm.localExampleRecipient.text)}}):_vm._e(),(_vm.useCodingZone)?_c('div',{staticClass:"coding-zone",style:({
        top: ((_vm.codingZoneInPX.posY) + "px"),
        left: ((_vm.codingZoneInPX.posX) + "px"),
        width: ((_vm.codingZoneInPX.width) + "px"),
        height: ((_vm.codingZoneInPX.height) + "px"),
      })}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.text.length > 0),expression:"text.length > 0"}],ref:_vm.useMovement ? 'textContainer' : 'disabledTextContainer',staticClass:"text-container",style:({
        fontFamily: 'helvetica',
        cursor: _vm.useMovement ? 'move' : 'default',
        position: 'absolute',
        top: '0px',
        left: '0px',
        fontSize: _vm.css.fontSize + 'px',
        paddingLeft: ((_vm.css.paddingLeft) + "px"),
        paddingRight: ((_vm.css.paddingRight) + "px"),
        paddingTop: ((_vm.css.paddingTop) + "px"),
        paddingBottom: ((_vm.css.paddingBottom) + "px"),
        lineHeight: ((_vm.css.lineHeight) + "px"),
        width: ((_vm.css.width) + "px"),
        height: _vm.useSquareContainer ? ((_vm.css.height) + "px") : "auto",
        transform: ("translate(" + (_vm.css.posX) + "px, " + (_vm.css.posY) + "px) rotate(" + (_vm.css.rotation) + "deg)")
      }),on:{"mouseenter":function($event){_vm.showHandles = true},"mouseleave":function($event){_vm.showHandles = false}}},[_vm._v(" "+_vm._s(_vm.localText)+" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHandles && _vm.useRotationHandle),expression:"showHandles && useRotationHandle"}],staticClass:"rotation-handle-increment",on:{"click":_vm.incrementRotation}},[_c(VIcon,{staticClass:"iconColor",attrs:{"small":"","left":""}},[_vm._v(_vm._s(_vm.icons.mdiRotateRight))])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHandles && _vm.useRotationHandle),expression:"showHandles && useRotationHandle"}],staticClass:"rotation-handle-decrement",on:{"click":_vm.decrementRotation}},[_c(VIcon,{staticClass:"iconColor",attrs:{"small":"","left":""}},[_vm._v(_vm._s(_vm.icons.mdiRotateLeft))])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHandles && _vm.useWidthHandle),expression:"showHandles && useWidthHandle"}],ref:"widthHandle",staticClass:"width-handle"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHandles && _vm.useWidthAndHeightHandle),expression:"showHandles && useWidthAndHeightHandle"}],ref:"widthAndHeightHandle",staticClass:"width-and-height-handle"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHandles && _vm.useFontHandle),expression:"showHandles && useFontHandle"}],staticClass:"font-handle-increment",on:{"click":_vm.incrementFontSize}},[_c(VIcon,{staticClass:"iconColor",attrs:{"small":"","left":""}},[_vm._v(_vm._s(_vm.icons.mdiPlusBox))])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHandles && _vm.useFontHandle),expression:"showHandles && useFontHandle"}],staticClass:"font-handle-decrement",on:{"click":_vm.decrementFontSize}},[_c(VIcon,{staticClass:"iconColor",attrs:{"small":"","left":""}},[_vm._v(_vm._s(_vm.icons.mdiMinusBox))])],1)]),_c('div',{staticClass:"areaToCutLeft",style:({
        width: _vm.css.cutWidth + 'px',
        height: '100%',
        background: 'rgba(199, 49, 131, 0.5)'
      })}),_c('div',{staticClass:"areaToCutRight",style:({
        width: _vm.css.cutWidth + 'px',
        height: '100%',
        background: 'rgba(199, 49, 131, 0.5)'
      })}),_c('div',{staticClass:"areaToCutTop",style:({
        width: '100%',
        height: _vm.css.cutWidth + 'px',
        background: 'rgba(199, 49, 131, 0.5)'
      })}),_c('div',{staticClass:"areaToCutBottom",style:({
        width: '100%',
        height: _vm.css.cutWidth + 'px',
        background: 'rgba(199, 49, 131, 0.5)'
      })})],1),(_vm.srcLocale)?_c('div',[_vm._v(" Die Druckversion kann leicht abweichen. "),_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.openDialog}},[_vm._v("Hier ansehen.")]),_c(VDialog,{attrs:{"width":"900px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v("Druckversion")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.showDialog = false}}},[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)],1),_c(VCardText,[_c('vue-pdf',{key:_vm.printVersionKey,attrs:{"src":_vm.printVersionSrc}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(" Schließen ")]),_c(VSpacer)],1)],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }