<template>

  <div>
    <div style="position: relative;top:0;left:0;">
      <vue-pdf v-bind="pdfProps" @loaded="onPdfLoad" ref="pdfContainer" />
      <div
        v-if="useStampAndRecipientZone"
        class="stamp-and-recipient-zone"
        :style="{
          top: `${stampAndRecipientZoneInPX.posY}px`,
          left: `${stampAndRecipientZoneInPX.posX}px`,
          width: `${stampAndRecipientZoneInPX.width}px`,
          height: `${stampAndRecipientZoneInPX.height}px`,
        }"
      ></div>
      <div
        v-if="localExampleRecipient.text != ''"
        class="exampleRecipient"
        :style="{
          top: `${localExampleRecipient.posYInPX}px`,
          left: `${localExampleRecipient.posXInPX}px`,
          width: `${localExampleRecipient.widthInPX}px`,
          fontSize: `${localExampleRecipient.fontSizeInPX}px`,
          lineHeight: `${localExampleRecipient.lineHeightInPX}px`,
        }"
        v-html="localExampleRecipient.text"
      ></div>
      <div v-if="useCodingZone"
        class="coding-zone"
        :style="{
          top: `${codingZoneInPX.posY}px`,
          left: `${codingZoneInPX.posX}px`,
          width: `${codingZoneInPX.width}px`,
          height: `${codingZoneInPX.height}px`,
        }"
      ></div>
      <div
        v-show="text.length > 0"
        :ref="useMovement ? 'textContainer' : 'disabledTextContainer'"
        class="text-container"
        :style="{
          fontFamily: 'helvetica',
          cursor: useMovement ? 'move' : 'default',
          position: 'absolute',
          top: '0px',
          left: '0px',
          fontSize: css.fontSize + 'px',
          paddingLeft: `${css.paddingLeft}px`,
          paddingRight: `${css.paddingRight}px`,
          paddingTop: `${css.paddingTop}px`,
          paddingBottom: `${css.paddingBottom}px`,
          lineHeight: `${css.lineHeight}px`,
          width: `${css.width}px`,
          height: useSquareContainer ? `${css.height}px` : `auto`,
          transform: `translate(${css.posX}px, ${css.posY}px) rotate(${css.rotation}deg)`
        }"
        @mouseenter="showHandles = true"
        @mouseleave="showHandles = false"
      >
        {{localText}}
        <div v-show="showHandles && useRotationHandle" @click="incrementRotation" class="rotation-handle-increment"><v-icon small left class="iconColor">{{icons.mdiRotateRight}}</v-icon></div>
        <div v-show="showHandles && useRotationHandle" @click="decrementRotation" class="rotation-handle-decrement"><v-icon small left class="iconColor">{{icons.mdiRotateLeft}}</v-icon></div>
        <div v-show="showHandles && useWidthHandle" ref="widthHandle" class="width-handle"></div>
        <div v-show="showHandles && useWidthAndHeightHandle" ref="widthAndHeightHandle" class="width-and-height-handle"></div>
        <div v-show="showHandles && useFontHandle" @click="incrementFontSize" class="font-handle-increment"><v-icon small left class="iconColor">{{icons.mdiPlusBox}}</v-icon></div>
        <div v-show="showHandles && useFontHandle" @click="decrementFontSize" class="font-handle-decrement"><v-icon small left class="iconColor">{{icons.mdiMinusBox}}</v-icon></div>
      </div>
      <div
        class="areaToCutLeft"
        :style="{
          width: css.cutWidth + 'px',
          height: '100%',
          background: 'rgba(199, 49, 131, 0.5)'
        }"
      ></div>
      <div
        class="areaToCutRight"
        :style="{
          width: css.cutWidth + 'px',
          height: '100%',
          background: 'rgba(199, 49, 131, 0.5)'
        }"
      ></div>
      <div
        class="areaToCutTop"
        :style="{
          width: '100%',
          height: css.cutWidth + 'px',
          background: 'rgba(199, 49, 131, 0.5)'
        }"
      ></div>
      <div
        class="areaToCutBottom"
        :style="{
          width: '100%',
          height: css.cutWidth + 'px',
          background: 'rgba(199, 49, 131, 0.5)'
        }"
      ></div>
    </div>
    <div v-if="srcLocale">
      Die Druckversion kann leicht abweichen. <a href="javascript:void(0)" @click="openDialog">Hier ansehen.</a>
      <v-dialog
        v-model="showDialog"
        width="900px"
      >
        <v-card>

          <v-toolbar flat>
            <v-toolbar-title>Druckversion</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="showDialog = false">
              <v-icon size="18" >
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <vue-pdf :src="printVersionSrc" :key="printVersionKey"/>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              outlined
              @click="showDialog = false"
            >
              Schließen
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>

</template>
<script>
import VuePdf from 'vue-pdf';
import interact from 'interactjs';
import { ref, onMounted, defineComponent, computed, getCurrentInstance, watch } from '@vue/composition-api';
import {
  mdiPlusBoxOutline,
  mdiMinusBoxOutline,
  mdiPlusBox,
  mdiMinusBox,
  mdiRotateLeft,
  mdiRotateRight,
  mdiArrowSplitVertical ,
  mdiArrowBottomRight ,
  mdiClose ,
} from '@mdi/js'
import isBlob from "is-blob";
import axios from "axios";

export default defineComponent({
  name: 'VuePdfWithDraggable',
  components: {
    VuePdf,
  },
  props: {
    ...VuePdf.props,
    text: {
      type: String,
      default: ""
    },
    srcLocale: {
      type: String,
      default: ""
    },
    dpi: {
      type: Number,
      default: 72
    },
    posX: {
      type: Number,
      default: 3
    },
    posY: {
      type: Number,
      default: 3
    },
    rotation: {
      type: Number,
      default: 0
    },
    fontSize: {
      type: Number,
      default: 2
    },
    lineHeight: {
      type: Number,
      default: 2
    },
    paddingLeft: {
      type: Number,
      default: 1
    },
    paddingRight: {
      type: Number,
      default: 1
    },
    paddingTop: {
      type: Number,
      default: 1
    },
    paddingBottom: {
      type: Number,
      default: 1
    },
    width: {
      type: Number,
      default: 55
    },
    useMovement: {
      type: Boolean,
      default: false
    },
    useFontHandle: {
      type: Boolean,
      default: false
    },
    useRotationHandle: {
      type: Boolean,
      default: false
    },
    useWidthHandle: {
      type: Boolean,
      default: false
    },
    useWidthAndHeightHandle: {
      type: Boolean,
      default: false
    },
    useSquareContainer: {
      type: Boolean,
      default: false
    },
    useStampAndRecipientZone: {
      type: Boolean,
      default: false
    },
    useCodingZone: {
      type: Boolean,
      default: false
    },
    pageHeight: {
      type: Number,
      validator(value) {
        return Number.isFinite(value) && !Number.isInteger(value);
      }
    },
    pageWidth: {
      type: Number,
      validator(value) {
        return Number.isFinite(value) && !Number.isInteger(value);
      }
    },
    pageSite: {
      type: Number,
      default: 1
    },
    exampleRecipient:{
      type: String,
      default: ""
    }

  },
  emits: ['update:coordinates'],
  setup(props, { emit }) {

    const localText = ref(JSON.parse(JSON.stringify(props.text)));

    watch(() => props.text, (newValue) => {
      localText.value = newValue;
    });

    const textContainer = ref(null);
    const pdfContainer = ref(null);
    const widthHandle = ref(null);
    const widthAndHeightHandle = ref(null);
    const pdfDimensions = ref({ width: 0, height: 0 });
    const showHandles = ref(false);
    const showDialog = ref(false);
    const coordinates = ref({});

    const cutInMM = 3;


    //pageWidth - width - 5mm - schnittrand


    const localExampleRecipient = ref({
      posXInMM: 169,
      posYInMM: 62,
      widthInMM: 64,
      fontSizeInMM: 3.88,
      lineHeightInMM: 4,
      posXInPX: 0,
      posYInPX: 0,
      widthInPX: 0,
      fontSizeInPX: 0,
      lineHeightInPX: 0,
      text: JSON.parse(JSON.stringify(props.exampleRecipient)),
    });

    const stampAndRecipientZoneInMM = {
      posX: props.pageWidth - (74 + cutInMM),
      posY: 0,
      width:74 + cutInMM,
      height:props.pageHeight
    };
    const stampAndRecipientZoneInPX = ref({
      posX: 0,
      posY: 0,
      width:0,
      height:0
    });
    const codingZoneInMM = {
      posX: props.pageWidth - (150 + cutInMM),
      posY: props.pageHeight - (15 + cutInMM),
      width:150 + cutInMM,
      height:15 + cutInMM
    };
    const codingZoneInPX = ref({
      posX: 0,
      posY: 0,
      width:0,
      height:0
    });


    const css = ref({
      posX: JSON.parse(JSON.stringify(props.posX)),
      posY: JSON.parse(JSON.stringify(props.posY)),
      fontSize: JSON.parse(JSON.stringify(props.fontSize)),
      lineHeight: JSON.parse(JSON.stringify(props.lineHeight)),
      paddingLeft: JSON.parse(JSON.stringify(props.paddingLeft)),
      paddingRight: JSON.parse(JSON.stringify(props.paddingRight)),
      paddingTop: JSON.parse(JSON.stringify(props.paddingTop)),
      paddingBottom: JSON.parse(JSON.stringify(props.paddingBottom)),
      width: JSON.parse(JSON.stringify(props.width)),
      rotation: JSON.parse(JSON.stringify(props.rotation)),
      height: props.useSquareContainer ? props.width : 0,
      cutWidth: 0,
      cutHeight: 0,
    });

    const vm = getCurrentInstance().proxy;

    const onPdfLoad = async () => {
      const pdfComponent = vm.$refs.pdfContainer;
      if (pdfComponent && pdfComponent.pdf) {
        pdfComponent.$on('page-size', (width, height, scale) => {
          const actualWidth = width;
          const actualHeight = height;

          pdfDimensions.value = {
            width: actualWidth,
            height: actualHeight,
            scale: scale
          };

          console.log('scale',pdfDimensions.value.scale)

          css.value.posX = calculateInPX(props.posX, props.pageWidth, actualWidth);
          css.value.posY = calculateInPX(props.posY, props.pageHeight, actualHeight);
          css.value.fontSize = calculateInPX(props.fontSize, props.pageHeight, actualHeight);
          css.value.paddingLeft = calculateInPX(props.paddingLeft, props.pageWidth, actualWidth);
          css.value.paddingRight = calculateInPX(props.paddingRight, props.pageWidth, actualWidth);
          css.value.paddingTop = calculateInPX(props.paddingTop, props.pageHeight, actualHeight);
          css.value.paddingBottom = calculateInPX(props.paddingBottom, props.pageHeight, actualHeight);
          css.value.lineHeight = calculateInPX(props.lineHeight, props.pageHeight, actualHeight);
          css.value.width = calculateInPX(props.width, props.pageWidth, actualWidth);
          css.value.cutHeight = calculateInPX(cutInMM, props.pageHeight, actualHeight);
          css.value.cutWidth = calculateInPX(cutInMM, props.pageWidth, actualWidth);

          if(props.useSquareContainer){
            css.value.height = css.value.width;
          }

          stampAndRecipientZoneInPX.value.posX = calculateInPX(stampAndRecipientZoneInMM.posX, props.pageWidth, actualWidth);
          stampAndRecipientZoneInPX.value.posY = calculateInPX(stampAndRecipientZoneInMM.posY, props.pageHeight, actualHeight);
          stampAndRecipientZoneInPX.value.width = calculateInPX(stampAndRecipientZoneInMM.width, props.pageWidth, actualWidth);
          stampAndRecipientZoneInPX.value.height = calculateInPX(stampAndRecipientZoneInMM.height, props.pageHeight, actualHeight);

          codingZoneInPX.value.posX = calculateInPX(codingZoneInMM.posX, props.pageWidth, actualWidth);
          codingZoneInPX.value.posY = calculateInPX(codingZoneInMM.posY, props.pageHeight, actualHeight);
          codingZoneInPX.value.width = calculateInPX(codingZoneInMM.width, props.pageWidth, actualWidth);
          codingZoneInPX.value.height = calculateInPX(codingZoneInMM.height, props.pageHeight, actualHeight);

          localExampleRecipient.value.posXInPX = calculateInPX(localExampleRecipient.value.posXInMM, props.pageWidth, actualWidth);
          localExampleRecipient.value.posYInPX = calculateInPX(localExampleRecipient.value.posYInMM, props.pageHeight, actualHeight);
          localExampleRecipient.value.widthInPX = calculateInPX(localExampleRecipient.value.widthInMM, props.pageWidth, actualWidth);
          localExampleRecipient.value.fontSizeInPX = calculateInPX(localExampleRecipient.value.fontSizeInMM, props.pageHeight, actualHeight);
          localExampleRecipient.value.lineHeightInPX = calculateInPX(localExampleRecipient.value.lineHeightInMM, props.pageHeight, actualHeight);
          emitCoordinates();
        });

        pdfComponent.pdf.loadPage(props.pageSite);
      } else {
        console.error('Failed to load PDF');
      }
    };

    const calculateInPX = (valueInMM, pageDimension, actualDimension) => {
      return (valueInMM / pageDimension) * actualDimension / window.devicePixelRatio;
    };

    const calculateInMM = (valueInPX, pageDimension, actualDimension) => {
      return (valueInPX * pageDimension) / (actualDimension / window.devicePixelRatio);
    };

    const printVersionSrc = ref(JSON.parse(JSON.stringify(props.src)))
    const printVersionKey = ref(0);

    const openDialog = () => {
      const fd = new FormData();
      fd.append('aktion',"showPrintVersionPdf");
      fd.append('src',props.srcLocale);
      fd.append('text',localText.value);

      if(localExampleRecipient.value.text != ""){
        fd.append('showExampleRecipient',1);
      }
      fd.append('coordinates',JSON.stringify(coordinates.value));
      axios
        .post('/api/ajaxServer/',fd)
        .then(response => {
          if (response.data.valid) {
            showDialog.value = true;
            printVersionSrc.value = response.data.src;
            printVersionKey.value++;
          }
          else{
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
        })
        .catch(error => {
          vm.$toastr.e(error,error);
        })
    }

    const dragMoveListener = (event) => {
      const target = event.target;
      const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
      const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

      const pdfRect = pdfContainer.value.$el.getBoundingClientRect();
      const textRect = target.getBoundingClientRect();

      let newX = x;
      let newY = y;

      const offsetX = (cutInMM / props.pageWidth) * pdfRect.width;
      const offsetY = (cutInMM / props.pageHeight) * pdfRect.height;

      if (textRect.left + event.dx < pdfRect.left + offsetX) {
        newX = pdfRect.left - textRect.left + x + offsetX;
      } else if (textRect.right + event.dx > pdfRect.right - offsetX) {
        newX = pdfRect.right - textRect.right + x - offsetX;
      }

      if (textRect.top + event.dy < pdfRect.top + offsetY) {
        newY = pdfRect.top - textRect.top + y + offsetY;
      } else if (textRect.bottom + event.dy > pdfRect.bottom - offsetY) {
        newY = pdfRect.bottom - textRect.bottom + y - offsetY;
      }

      target.style.transform = `translate(${newX}px, ${newY}px) rotate(${css.value.rotation}deg)`;
      target.setAttribute('data-x', newX);
      target.setAttribute('data-y', newY);

      css.value.posX = newX;
      css.value.posY = newY;
      emitCoordinates();
    };

    const incrementRotation = () => {
      css.value.rotation = (css.value.rotation + 90) % 360;
      textContainer.value.style.transform = `translate(${css.value.posX}px, ${css.value.posY}px) rotate(${css.value.rotation}deg)`;
      emitCoordinates();
    };

    const decrementRotation = () => {
      css.value.rotation = (css.value.rotation - 90) % 360;
      textContainer.value.style.transform = `translate(${css.value.posX}px, ${css.value.posY}px) rotate(${css.value.rotation}deg)`;
      emitCoordinates();
    };

    const incrementFontSize = () => {
      css.value.fontSize = css.value.fontSize + 1;
      css.value.lineHeight = css.value.lineHeight + 1;
      emitCoordinates();
    };

    const decrementFontSize = () => {
      css.value.fontSize = css.value.fontSize - 1;
      css.value.lineHeight = css.value.lineHeight - 1;
      emitCoordinates();
    };

    const resizeWidth = (event) => {
      const deltaX = event.dx;
      css.value.width += deltaX;
      textContainer.value.style.width = `${css.value.width}px`;
      emitCoordinates();
    };


    const resizeWidthAndHeight = (event) => {
      const deltaX = event.dx;
      css.value.width += deltaX;
      textContainer.value.style.width = `${css.value.width}px`;
      css.value.height = css.value.width;
      textContainer.value.style.height = `${css.value.height}px`;
      emitCoordinates();
    };

    const emitCoordinates = () => {

      const posXInMM = calculateInMM(css.value.posX, props.pageWidth, pdfDimensions.value.width);
      const posYInMM = calculateInMM(css.value.posY, props.pageHeight, pdfDimensions.value.height);
      const fontSizeInMM = calculateInMM(css.value.fontSize, props.pageHeight, pdfDimensions.value.height);
      const paddingLeftInMM = calculateInMM(css.value.paddingLeft, props.pageWidth, pdfDimensions.value.width);
      const paddingRightInMM = calculateInMM(css.value.paddingRight, props.pageWidth, pdfDimensions.value.width);
      const paddingTopInMM = calculateInMM(css.value.paddingTop, props.pageHeight, pdfDimensions.value.height);
      const paddingBottomInMM = calculateInMM(css.value.paddingBottom, props.pageHeight, pdfDimensions.value.height);
      const lineHeightInMM = calculateInMM(css.value.lineHeight, props.pageHeight, pdfDimensions.value.height);
      const widthInMM = calculateInMM(css.value.width, props.pageWidth, pdfDimensions.value.width);

      coordinates.value = {
        posXInMM: posXInMM,
        posYInMM: posYInMM,
        fontSizeInMM: fontSizeInMM,
        paddingLeftInMM: paddingLeftInMM,
        paddingRightInMM: paddingRightInMM,
        paddingTopInMM: paddingTopInMM,
        paddingBottomInMM: paddingBottomInMM,
        lineHeightInMM: lineHeightInMM,
        widthInMM: widthInMM,
        rotationInDeg: css.value.rotation,
        pageWidth: props.pageWidth,
        pageHeight: props.pageHeight,
        css: css,
        text: localText.value,
        stampAndRecipientZoneInMM: stampAndRecipientZoneInMM,
        codingZoneInMM: codingZoneInMM,
        useSquareContainer: props.useSquareContainer,
        useStampAndRecipientZone: props.useStampAndRecipientZone,
        useCodingZone: props.useCodingZone,
        exampleRecipient: localExampleRecipient.value,
      };
      //console.log('coordinates', coordinates);
      emit('update:coordinates', coordinates);
    };

    let previousRatio = window.devicePixelRatio;

    window.addEventListener('resize', () => {
      if (window.devicePixelRatio !== previousRatio) {
        emitCoordinates();
        previousRatio = window.devicePixelRatio;
      }
    });

    onMounted(() => {

      if (props.useMovement) {
        textContainer.value.setAttribute('data-x', css.value.posX);
        textContainer.value.setAttribute('data-Y', css.value.posY);
      }

      interact(textContainer.value)
        .draggable({
          onmove: dragMoveListener,
        })
        .styleCursor(false)
        .styleCursor({
          touchAction: 'none',
        });

      interact(widthHandle.value)
        .draggable({
          onmove: resizeWidth,
        })
        .styleCursor(false);

      interact(widthAndHeightHandle.value)
        .draggable({
          onmove: resizeWidthAndHeight,
        })
        .styleCursor(false);
    });

    const pdfProps = ref({
      ...props
    });

    return {
      pdfProps,
      textContainer,
      pdfContainer,
      widthHandle,
      widthAndHeightHandle,
      pdfDimensions,
      onPdfLoad,
      localText,
      css,
      showHandles,
      openDialog,
      showDialog,
      printVersionSrc,
      printVersionKey,
      incrementRotation,
      decrementRotation,
      incrementFontSize,
      decrementFontSize,
      stampAndRecipientZoneInPX,
      codingZoneInPX,
      localExampleRecipient,
      icons: {
        mdiPlusBoxOutline,
        mdiMinusBoxOutline,
        mdiPlusBox,
        mdiMinusBox,
        mdiRotateLeft,
        mdiRotateRight,
        mdiArrowSplitVertical ,
        mdiArrowBottomRight ,
        mdiClose ,
      },
    };
  },
});
</script>
<style>
.text-container {
  background-color: white;
  cursor: move;
  user-select: none;
  color: #000;
}
.rotation-handle-increment {
  position: absolute;
  left: -10px;
  top: -15px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  user-select: none;
  /*display: none;*/
}
.rotation-handle-decrement {
  position: absolute;
  right: -10px;
  bottom: -15px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  user-select: none;
  /*display: none;*/
}
.font-handle-increment {
  position: absolute;
  left: 0px;
  bottom: -15px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  /* display: none; */
}
.font-handle-decrement {
  position: absolute;
  left: 13px;
  bottom: -15px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  /* display: none; */
}
.width-handle{
  position: absolute;
  right: -10px;
  top: 10%;
  width: 10px;
  height: 20px;
  background-color: transparent;
  cursor: ew-resize;
  font-size: 14px;
  color: #fff;
  user-select: none;
  /*display: none;*/
}
.width-and-height-handle {
  position: absolute;
  right: -7px;
  bottom: -16px;
  width: 10px;
  height: 20px;
  background-color: transparent;
  cursor: se-resize;
  font-size: 14px;
  color: #fff;
  user-select: none;
  /*display: none;*/
}
.text-container:hover .rotation-handle-increment,
.text-container:hover .width-handle,
.text-container:hover .width-and-height-handle {
  display: block;
}

.areaToCutLeft,
.areaToCutRight,
.areaToCutTop,
.areaToCutBottom{
  position: absolute;
  background: red;
  box-sizing: border-box;
  background-repeat: repeat-y;
}

.areaToCutLeft{
  left:0;
  top:0;
}

.areaToCutRight{
  right:0;
  top:0;
}

.areaToCutTop{
  top:0;
  left:0;
}

.areaToCutBottom{
  bottom:0;
  left:0;
}
.iconColor{
  color:red !important;
  opacity:1 !important;
}

.exampleRecipient{
  position: absolute;
  border: 1px dashed #c62323;
  color:#000;
}

.stamp-and-recipient-zone{
  position: absolute;
  background: #fff;
  width:200px;
  height:50px;
  top: 100px;
  left: 1000px;
  border: 1px dashed #c62323;
}

.coding-zone{
  position: absolute;
  background: #fff;
  width:200px;
  height:50px;
  top: 100px;
  left: 1000px;
  border: 1px dashed #c62323;
}

</style>

